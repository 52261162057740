// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'qa',
  host: 'edcastkpn.com',

  hedwigUrl: 'https://hedwig.edcastkpn.com',
  saharaUrl: 'https://my.edcastkpn.com',
  instructorMarketUrl: 'https://instructors.edcastkpn.com',
  serviceMarketUrl: 'https://instructors.edcastkpn.com',
  integrationFramework: 'https://integration.edcastkpn.com',
  amberBaseUrl: 'https://amber.edcastkpn.com',
  amberUrl: 'https://amber.edcastkpn.com/api',
  bouncerUrl: 'https://account.edcastkpn.com',
  sphinxUrl: 'https://downloads.edcastkpn.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAWLQHGLXHE6ZVEZWE',
    s3Url: 'https://downloads.edcastkpn.com/proxy/service/jwt',
    cdnBucket: 'leap-qa-cdn',
    uploadsBucket: 'leap-qa-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'test_ZVI4LE36HVE2FCXGOAHXRIADDA7XNF5K'
  },

  okta: {
    issuer: 'https://edcast.oktapreview.com/oauth2/aus1535zkdpcWhTy30h8',
    clientId: '0oa243em8sfr6EscZ0h8',
    prefix: 'etisalatqa',
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
